export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
               // url: '/result/getPersonalResult',//for antarim
               url: '/result/getPersonalResultFinal', //for antim                
                method: 'POST',
                body: payload,
               
            };
        },
       // invalidatesTags: ['allDistricts'],
    })
