import React,{useEffect,useState} from 'react'
//import '../assets/js/main.js'
import { Link, NavLink } from 'react-router-dom';
//import '../assets/css/style.css'
//import AOS from '../assets/vendor/aos/aos.js'
//import '../assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
// import GLightbox from '../assets/vendor/glightbox/js/glightbox.min.js'
// import Isotope from '../assets/vendor/isotope-layout/isotope.pkgd.min.js'
// import Swiper from '../assets/vendor/swiper/swiper-bundle.min.js'
//import { Swiper, SwiperSlide } from "swiper/react";
import Waypoint from '../assets/vendor/waypoints/noframework.waypoints.js'


function HeaderNavbar() {
  // useEffect(() => { 
  //   (function() {
     
  //     const select = (el, all = false) => {
  //       el = el.trim()
  //       if (all) {
  //         return [...document.querySelectorAll(el)]
  //       } else {
  //         return document.querySelector(el)
  //       }
  //     }
    
  //     const on = (type, el, listener, all = false) => {
  //       let selectEl = select(el, all)
  //       if (selectEl) {
  //         if (all) {
  //           selectEl.forEach(e => e.addEventListener(type, listener))
  //         } else {
  //           selectEl.addEventListener(type, listener)
  //         }
  //       }
  //     }
    
  //     const onscroll = (el, listener) => {
  //       el.addEventListener('scroll', listener)
  //     }    
  //     let navbarlinks = select('#navbar .scrollto', true)
  //     const navbarlinksActive = () => {
  //       let position = window.scrollY + 200
  //       navbarlinks.forEach(navbarlink => {
  //         if (!navbarlink.hash) return
  //         let section = select(navbarlink.hash)
  //         if (!section) return
  //         if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
  //           navbarlink.classList.add('active')
  //         } else {
  //           navbarlink.classList.remove('active')
  //         }
  //       })
  //     }
  //     window.addEventListener('load', navbarlinksActive)
  //     onscroll(document, navbarlinksActive)
    
  //     const scrollto = (el) => {
  //       let header = select('#header')
  //       let offset = header.offsetHeight
    
  //       let elementPos = select(el).offsetTop
  //       window.scrollTo({
  //         top: elementPos - offset,
  //         behavior: 'smooth'
  //       })
  //     }
    
  //     let selectHeader = select('#header')
  //     if (selectHeader) {
  //       const headerScrolled = () => {
  //         if (window.scrollY > 100) {
  //           selectHeader.classList.add('header-scrolled')
  //         } else {
  //           selectHeader.classList.remove('header-scrolled')
  //         }
  //       }
  //       window.addEventListener('load', headerScrolled)
  //       onscroll(document, headerScrolled)
  //     }
    
  //     let backtotop = select('.back-to-top')
  //     if (backtotop) {
  //       const toggleBacktotop = () => {
  //         if (window.scrollY > 100) {
  //           backtotop.classList.add('active')
  //         } else {
  //           backtotop.classList.remove('active')
  //         }
  //       }
  //       window.addEventListener('load', toggleBacktotop)
  //       onscroll(document, toggleBacktotop)
  //     }
    
  //     // on('click', '.navbar .dropdown > a', function(e) {
  //     //   if (select('#navbar').classList.contains('navbar-mobile')) {
  //     //     e.preventDefault()
  //     //     this.nextElementSibling.classList.toggle('dropdown-active')
  //     //   }
  //     // }, true)
    
  //     window.addEventListener('load', () => {
  //       if (window.location.hash) {
  //         if (select(window.location.hash)) {
  //           scrollto(window.location.hash)
  //         }
  //       }
  //     });
    
  //     let preloader = select('#preloader');
  //     if (preloader) {
  //       window.addEventListener('load', () => {
  //         preloader.remove()
  //       });
  //     }
    
  //     /**
  //      * Initiate  glightbox 
  //      */
  //     // const glightbox = GLightbox({
  //     //   selector: '.glightbox'
  //     // });
    
  //     /**
  //      * Skills animation
  //      */
  //     let skilsContent = select('.skills-content');
  //     // if (skilsContent) {
  //     //   new Waypoint({
  //     //     element: skilsContent,
  //     //     offset: '80%',
  //     //     handler: function(direction) {
  //     //       let progress = select('.progress .progress-bar', true);
  //     //       progress.forEach((el) => {
  //     //         el.style.width = el.getAttribute('aria-valuenow') + '%'
  //     //       });
  //     //     }
  //     //   })
  //     // }
    
  //     /**
  //      * Porfolio isotope and filter
  //      */
  //     // window.addEventListener('load', () => {
  //     //   let portfolioContainer = select('.portfolio-container');
  //     //   if (portfolioContainer) {
  //     //     let portfolioIsotope = new Isotope(portfolioContainer, {
  //     //       itemSelector: '.portfolio-item'
  //     //     });
    
  //     //     let portfolioFilters = select('#portfolio-flters li', true);
    
  //     //     on('click', '#portfolio-flters li', function(e) {
  //     //       e.preventDefault();
  //     //       portfolioFilters.forEach(function(el) {
  //     //         el.classList.remove('filter-active');
  //     //       });
  //     //       this.classList.add('filter-active');
    
  //     //       portfolioIsotope.arrange({
  //     //         filter: this.getAttribute('data-filter')
  //     //       });
  //     //       portfolioIsotope.on('arrangeComplete', function() {
  //     //        // AOS.refresh()
  //     //       });
  //     //     }, true);
  //     //   }
    
  //     // });
    
  //     /**
  //      * Initiate portfolio lightbox 
  //      */
  //     // const portfolioLightbox = GLightbox({
  //     //   selector: '.portfolio-lightbox'
  //     // });
    
  //     /**
  //      * Portfolio details slider
  //      */
  //     // new Swiper('.portfolio-details-slider', {
  //     //   speed: 400,
  //     //   loop: true,
  //     //   autoplay: {
  //     //     delay: 5000,
  //     //     disableOnInteraction: false
  //     //   },
  //     //   pagination: {
  //     //     el: '.swiper-pagination',
  //     //     type: 'bullets',
  //     //     clickable: true
  //     //   }
  //     // });
    
  //     /**
  //      * Animation on scroll
  //      */
  //     window.addEventListener('load', () => {
  //       // AOS.init({
  //       //   duration: 1000,
  //       //   easing: "ease-in-out",
  //       //   once: true,
  //       //   mirror: false
  //       // });
  //     });
    
  //   })()
  //   window.scrollTo(0, 0)
  // }, []);

  useEffect(() => { 
    (function() {
     
      const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }
    
      const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
          if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener))
          } else {
            selectEl.addEventListener(type, listener)
          }
        }
      }
    
      const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
      }    
      let navbarlinks = select('#navbar .scrollto', true)
      const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
          if (!navbarlink.hash) return
          let section = select(navbarlink.hash)
          if (!section) return
          if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active')
          } else {
            navbarlink.classList.remove('active')
          }
        })
      }
      window.addEventListener('load', navbarlinksActive)
      onscroll(document, navbarlinksActive)
    
      const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight
    
        let elementPos = select(el).offsetTop
        window.scrollTo({
          top: elementPos - offset,
          behavior: 'smooth'
        })
      }
    
      let selectHeader = select('#header')
      if (selectHeader) {
        const headerScrolled = () => {
          if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled')
          } else {
            selectHeader.classList.remove('header-scrolled')
          }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
      }
    
      let backtotop = select('.back-to-top')
      if (backtotop) {
        const toggleBacktotop = () => {
          if (window.scrollY > 100) {
            backtotop.classList.add('active')
          } else {
            backtotop.classList.remove('active')
          }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
      }
    
     
    
      // window.addEventListener('load', () => {
      //   if (window.location.hash) {
      //     if (select(window.location.hash)) {
      //       scrollto(window.location.hash)
      //     }
      //   }
      // });
    
      // let preloader = select('#preloader');
      // if (preloader) {
      //   window.addEventListener('load', () => {
      //     preloader.remove()
      //   });
      // }
    
     
      // const glightbox = GLightbox({
      //   selector: '.glightbox'
      // });
    
    
      // let skilsContent = select('.skills-content');
      // if (skilsContent) {
      //   new Waypoint({
      //     element: skilsContent,
      //     offset: '80%',
      //     handler: function(direction) {
      //       let progress = select('.progress .progress-bar', true);
      //       progress.forEach((el) => {
      //         el.style.width = el.getAttribute('aria-valuenow') + '%'
      //       });
      //     }
      //   })
      // }
    
      // window.addEventListener('load', () => {
      //   let portfolioContainer = select('.portfolio-container');
      //   if (portfolioContainer) {
      //     let portfolioIsotope = new Isotope(portfolioContainer, {
      //       itemSelector: '.portfolio-item'
      //     });
    
      //     let portfolioFilters = select('#portfolio-flters li', true);
    
      //     on('click', '#portfolio-flters li', function(e) {
      //       e.preventDefault();
      //       portfolioFilters.forEach(function(el) {
      //         el.classList.remove('filter-active');
      //       });
      //       this.classList.add('filter-active');
    
      //       portfolioIsotope.arrange({
      //         filter: this.getAttribute('data-filter')
      //       });
           
      //     }, true);
      //   }
    
      // });
    
     
      // const portfolioLightbox = GLightbox({
      //   selector: '.portfolio-lightbox'
      // });
    
     
      // window.addEventListener('load', () => {      
      // });
    
    })()
    window.scrollTo(0, 0)
  }, []);


const [toggleOpen,setToggleOpen]=useState(false)
const [showDropdown,setshowDropdown]=useState(true)

const openDropdown=()=>{
 
  var y = document.getElementById("dropdown");

  if(showDropdown)
  {
    y.classList.add('dropdown-active')
  setshowDropdown(false)

  }
  else
  {
    y.classList.remove('dropdown-active')
    setshowDropdown(true)
  }
 
}

 
    const toggleMobile=()=>{
     
      var x = document.getElementById("navbar");
      var y = document.getElementById("mobile-nav-toggle");
      
      if(toggleOpen==true)
      {
        x.classList.remove('navbar-mobile')
        x.classList.add('navbar')
        y.classList.remove('bi-x')
        y.classList.add('bi-list')
        setToggleOpen(false)
      }
      if(toggleOpen==false)
      {
        x.classList.remove('navbar')
        x.classList.add('navbar-mobile')
        y.classList.remove('bi-list')
        y.classList.add('bi-x')
        setToggleOpen(true)
      }
    
    }

  return (
    <>
         <header id="header" class="fixed-top ">
    <div class="container d-flex align-items-center">

      <h1 class="logo me-auto">
      <NavLink 
    to='/'
    
    > <span style={{color:'#ffffff'}}>सांगली शिक्षण संस्था</span>
        </NavLink>
        </h1>
     
      <nav id="navbar" class="navbar">
        <ul>
        <li className='nav-link'>
        <NavLink 
    to='/'    
    > 
              Home
              </NavLink>
        </li>

        <li class="dropdown dropdown-active" >
          <a href="#" onClick={()=>openDropdown()}>
            <span style={{color:'#ffffff'}}>Students</span> <i class="bi bi-chevron-down"></i></a>
            <ul id="dropdown">

              <li className='nav-link'><a href="#">Exam</a></li>
            
              <li className='nav-link'>
              {/* <NavLink 
    to='/resultlanding'
    
    > */}
     <NavLink 
    to='/resultlanding'
    
    >
                Result</NavLink></li>

                {/* <li className='nav-link'>
            
     <NavLink 
    to='/priceCerimony'
    
    >
                बक्षिस समारंभ फोटो</NavLink></li> */}

<li className='nav-link'>
            
            <NavLink 
           to='/examanswersheet'
           
           >
                       अंतिम उत्तरसूची 2025</NavLink></li>
                
            
            </ul>
          </li>
            <li>
         
            </li>
          <li>
          
             
              </li>
          <li>
           
            <NavLink 
    to='/schools'    
    > 
              Our Schools
              </NavLink>
             
            </li>
          {/* <li><a class="nav-link scrollto" href="#team">Live Sessions</a></li> */}
          {/* <li><a class="nav-link scrollto" href="#about">Gallery</a></li> */}

        
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
         
        </ul>
        <i id="mobile-nav-toggle" class="bi bi-list mobile-nav-toggle"
      onClick={()=>toggleMobile()}
        ></i>
         {/* <i class="bi bi-list mobile-nav-toggle"
     
        ></i> */}
      </nav>

    </div>
  </header>
    </>
  )
}

export default HeaderNavbar