import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';

function ResultLanding() {
 
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { 
    data: schools= [],  
    isLoading:loading=true,
    isSuccess:getSchoolSuccess=false
   } = useGetAllSchoolsQuery();
   useEffect(() => {    
     
    if (getSchoolSuccess) {
       
       
          setFilteredData(schools.data);
        
    }
   
}, [schools]);
    const ResultLandingScreen=()=>{  
    
      const handleFormSubmit = (event) => {
        event.preventDefault();
    
        const filteredItems = schools.data.filter((item) => {
          console.log("item filter", item.school_section)
          console.log("item filter", item.medium)
          console.log("filter1",filter1)
          console.log("filter1",filter2)
          return (
            item.school_section === filter1 &&
            item.medium === filter2
          );
        });
    console.log("filteredItems",filteredItems)
        setFilteredData(filteredItems);
       
      };  
      const handleLinkClick = () => {
        setSearchParams({ param1: 'value1', param2: 'value2' });
      }; 
      console.log("All Schools",schools)   
        return(
            <>
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>परीक्षा निकाल खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    

        <table className="table">
      <thead>
        <tr>
        <th style={{textAlign:'center'}}>Index</th>
         
          <th style={{textAlign:'center'}}> निकाल 2024-25</th>
         
        </tr>
      </thead>
      <tbody>
      {/* <tr>
            <td>1</td>
            <td>           
            <Link 
            to={{ pathname: '/shatabdiResult'}}
            style={{color:'blue'}}
            >
             
            शताब्दि शिष्यवृत्ती परीक्षा 2024-25 अंतरिम निकाल (४थी व ७वी)  पाहण्यासाठी क्लिक करा
              </Link>
              </td>           
          </tr> */}
          <tr>
            <td>1</td>
            <td>           
            <Link 
            to={{ pathname: '/shatabdiResult'}}
            style={{color:'blue'}}
            >
             
            शताब्दि शिष्यवृत्ती परीक्षा 2024-25 अंतिम निकाल (४थी व ७वी)  पाहण्यासाठी क्लिक करा
              </Link>
              </td>           
          </tr>
      <tr>
            <td>2</td>
            <td>           
            {/* <Link 
            to={{ pathname: '/tseresult'}}
            style={{color:'blue'}}
            >
             
अंतरीम निकाल 2023
गुणवत्ता शोध परीक्षा 2023 (१ली, २री, ३री, ६वी)
              </Link> */}
              <div class="alert alert-danger" role="alert">
              इ.१ली, २री, ३री व ६वी चा अंतरिम निकाल दि. १० एप्रिल २०२५ रोजी जाहीर केला जाणार आहे.
</div>
              </td>           
          </tr>
        
      </tbody>
    </table>
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>
{/* 
<footer id="footer">
<div class="footer-newsletter">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6" >
        <h4 style={{color:'white'}}>Find us On</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <i class="fa fa-youtube-play fa-3x "
       style={{paddingRight: "1em"}}
         aria-hidden="true"></i>
        <i class="fa fa-spotify fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-instagram fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-linkedin fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-facebook-square fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
</footer> */}


</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ResultLandingScreen()}
  </WebTemplate>
  )
}

export default ResultLanding