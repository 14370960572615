import React,{useEffect,useRef,useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useGetAllCentersQuery, useGetCenterMeritListShatabdiMutation, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Config } from '../Config';

function ShatabdiCenterwiseMeritList() {
    const [standard, setStandard] = useState('');
    const [center, setCenter] = useState('');
    const [fileName, setFileName] = useState('');
    const[isFileDownloadVisible,setIsFileDownloadVisible]=useState(false)
    const [filteredData, setFilteredData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  
 
  const { 
    data: centers= [],  
    isLoading:loading=true,
    isSuccess:getCentersSuccess=false
   } = useGetAllCentersQuery();
   const [GetCenterMeritListShatabdi, 
    { data, isSuccess, isLoading, isError, error }]
     = useGetCenterMeritListShatabdiMutation();
     useEffect(() => {
       
        validateForm();
      }, [center,standard]);
       useEffect(() => {    
         
        if (getCentersSuccess) {
           
           console.log("centers.data",centers)

          //  const options = centers.data.map((item) => ({
          //   value: item.center_id,
          //   label: item.center_name,
          // }));

          // setFilteredData(options);

              setFilteredData(centers.data);


            
        }
       
    }, [centers]);
    useEffect(() => {   
         
        // setStandard("")
        // setCenter("")
      if (isSuccess) {         
        console.log("data centerwise meritlist",data)   
        if(data.error) 
        {
            setFileName("")   
            setIsFileDownloadVisible(false)       
          toast.error(data.message)    
          return               
        }  
        setFileName(data.fileName)   
        setIsFileDownloadVisible(true)    
       toast.success(data.message)                       
      }
      else {
      console.log("In error")
         
      }
    }, [data]);
    const validateForm = () => {
         
        
      if (center !== '' && standard!=='') {
        setIsFormValid(true);
      } 
      else 
      {           
        setIsFormValid(false);
      }       
    }; 
    const handleFormSubmit = event => {
        GetCenterMeritListShatabdi({
          center_id:center,
          standard:standard,
          file_name:standard+"_"+center
        })
        };
    
  return (
    <>
     <div className='row'>
            <div className='col-md-4'>
            <div className='row' style={{marginTop:'50px'}}>
       <h4 style={{textAlign:'center'}}> केंद्रनिहाय गुणवत्ता यादी पहा</h4>
        </div>
          
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label style={{marginTop:'20px'}}>केंद्र निवडा:</Form.Label>
        <Form.Control as="select" value={center} onChange={e => setCenter(e.target.value)}>
          <option value="">Choose...</option>
          {filteredData.map(item => (
                        <option value={item.center_id}>{item.center_name}</option>
                      ))}
        </Form.Control>
      
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label style={{marginTop:'20px'}}>इयत्ता निवडा:</Form.Label>
        <Form.Control as="select" value={standard} onChange={e => setStandard(e.target.value)}>
          <option value="">Choose...</option>
          <option value="4">4</option>
          <option value="7">7</option>
        </Form.Control>
      
      </Form.Group>
      <Button
  style={{ marginTop: '20px' }}
  variant="primary"
  disabled={!isFormValid || isLoading}
  type="submit"
  onClick={()=>handleFormSubmit()}
>
  {isLoading ? (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : (
    'Submit'
  )}
</Button>
   
            </div>
            <div className='col-md-6' >
           {isFileDownloadVisible>0? 
           <table class="table"style={{marginTop:'40px',marginLeft:"40px"}} >
    <thead>

      <tr>
    
        <th colSpan={1} style={{textAlign:'center'}}>
           
        केंद्रनिहाय गुणवत्ता यादी पहा
           
         
          </th>
    
       
      </tr>
    </thead>
    <tbody>
   

      <tr>
        <td style={{textAlign:'left'}}>
        <a href={`${Config.API_URL_FILE}/CenterMeritList/shatabdi/${fileName}`} target='_blank'>
        केंद्रनिहाय गुणवत्ता यादी पाहण्यासाठी येथे क्लिक करा 
            </a>
            </td>
       
      </tr>
   
      
    
     
    </tbody>
  </table>:""}
            </div>
         
        </div>
    </>
  )
}

export default ShatabdiCenterwiseMeritList