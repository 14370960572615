import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import { Config } from '../Config';

function ExamAnswerSheet() {
 
    const ExamAnswerSheetScreen=()=>{  
    
        return(
            <>
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}> गुणवत्ता शोध व शताब्दी शिष्यवृत्ती परीक्षांच्या अंतिम उत्तरसूची खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    

        <table className="table">
      <thead>
        <tr>
        <th style={{textAlign:'center'}}>Index</th>
         
          <th style={{textAlign:'center'}}> गुणवत्ता शोध व शताब्दी शिष्यवृत्ती परीक्षांच्या अंतिम उत्तरसूची</th>
         
        </tr>
      </thead>
      <tbody>
      <tr>
            <td>1</td>
            <td>           
          
            
             {/* <a href='http://localhost:8002/uttarsuchi/antarim/1li.pdf' target='_blank'> */}
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/1li.pdf`} target='_blank'>

             गुणवत्ता शोध परीक्षा १ली अंतिम उत्तरसूची
            </a>
             
              </td>           
          </tr>
          <tr>
            <td>2</td>
            <td>           
                                 
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/2ri.pdf`} target='_blank'>

             गुणवत्ता शोध परीक्षा २री अंतिम उत्तरसूची
            </a>
             
              </td>           
          </tr>
          <tr>
            <td>3</td>
            <td>           
                                 
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/3ri.pdf`} target='_blank'>

             गुणवत्ता शोध परीक्षा ३री अंतिम उत्तरसूची
            </a>
             
              </td>           
          </tr>
        
          <tr>
            <td>4</td>
            <td>           
                                 
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/6vi.pdf`} target='_blank'>

             गुणवत्ता शोध परीक्षा ६वी अंतिम उत्तरसूची
            </a>
             
              </td>           
          </tr>
          <tr>
            <td>5</td>
            <td>           
                                 
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/4thi.pdf`} target='_blank'>

             शताब्दी शिष्यवृत्ती परीक्षा ४थी अंतिम उत्तरसूची 
            </a>
             
              </td>           
          </tr>
          <tr>
            <td>6</td>
            <td>           
                               
             <a href={`${Config.API_URL_FILE}/uttarsuchi/antim/7vi.pdf`} target='_blank'>

             शताब्दी शिष्यवृत्ती परीक्षा ७वी अंतिम उत्तरसूची 
            </a>
             
              </td>           
          </tr>
          
      </tbody>
    </table>
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>



</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ExamAnswerSheetScreen()}
  </WebTemplate>
  )
}

export default ExamAnswerSheet