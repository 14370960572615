export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
             
               url: '/result/checkCenterMeritListShatabdi', 
                method: 'POST',
                body: payload,
               
            };
        },
       // invalidatesTags: ['allDistricts'],
    })
