import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonelResult from '../Components/PersonelResult';
import qrcode from "../assets/img/qrcode.jpeg";
import ShatabdiCenterwiseMeritList from '../Components/ShatabdiCenterwiseMeritList';

function ShatabdiResult() {
 
    const ShatabdiResultScreen=()=>{  
    
        return(
            <>
             <ToastContainer />
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>शताब्दि शिष्यवृत्ती परीक्षा 2024-25 अंतिम निकाल (४थी व ७वी)</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
        <Tab.Container defaultActiveKey="index1">
  <Nav variant="tabs">
    <Nav.Item>
      <Nav.Link eventKey="index1">
      वैयक्तिक निकाल
      </Nav.Link>
    </Nav.Item>
  <Nav.Item>
      <Nav.Link eventKey="index2">
      केंद्रनिहाय गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item>
      {/* <Nav.Item>
      <Nav.Link eventKey="index2">
      केंद्र-इयत्ता निहाय गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item> */}
  </Nav>
  <div>
  <Tab.Content>
    <Tab.Pane eventKey="index1">
       
       <PersonelResult/>
   
    </Tab.Pane>
    <Tab.Pane eventKey="index2">
    <ShatabdiCenterwiseMeritList/>
    </Tab.Pane>
  </Tab.Content>
  </div>

</Tab.Container>
        </div>

      </div>
    </section>
    {/* <section id="services" class="services">
      <div class="container" data-aos="fade-up">
      <div class="alert alert-danger" role="alert">
      ज्या विद्यार्थ्यांच्या गुणांची फेरपडताळणी करायची आहे त्यांना दि. 25 मार्च 2025 अखेर एका पेपर साठी रु.50/-  इतके शुल्क भरुन फेरपडताळणी करता येईल. 
       रक्कम संस्थेकडे जमा करणेसाठी सोबत दिलेल्या संस्थेच्या बँक अकौंटसाठीचा QR कोड स्कॅन करुन गुगल पे, फोन पे ने रक्कम भरता येईल रक्कम भरल्या नंतर त्याचा स्क्रीन शाॅट विद्यार्थ्याच्या नावासह 9028831388 या क्रमांकावर वॉटस् अप मेसेज करावा अथवा संस्थेच्या कार्यालयात समक्ष येऊन रक्कम भरता येईल.  
      विद्यार्थ्याच्या नावामध्ये दुरुस्ती असल्यास वरील मोबाईल नंबरवर वाॅटस अप मेसेजने कळवावे म्हणजे प्रमाणपत्रावर ती दुरुस्ती करता येईल.
</div>
<div class="alert alert-primary" role="alert">
उदाहरणार्थ  मराठी व गणित (पेपर 1), इंग्रजी व बुद्धिमत्ता (पेपर 2), म्हणजेच दोन्ही पेपर ची फेरपडताळणी करायची असेल तर प्रत्येकी 50 या हिशोबानी 100 रुपये भरावे लागतील.
</div>
       
        <div class="row">
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon">
                
                <img src={qrcode} alt=""  class="img-fluid"/>
                
                </div>
              <h4 style={{fontSize:'20px'}}>QR Code</h4>
             
            </div>
          </div>
        </div>

      </div>
    </section> */}

</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ShatabdiResultScreen()}
  </WebTemplate>
  )
}

export default ShatabdiResult